import { mapActions, mapMutations } from "vuex";
import { Component } from "vue-property-decorator";
import { VueWizard } from "@/vue-wizard";
import { store, storeTypes } from "@/app/store";
//require('/src/assets/css/app.css');
@Component({
    name: "login-form",
    methods: {
        ...mapMutations(["setUser"]),
        ...mapActions(["login"]),
    },
})
export default class LoginForm extends VueWizard {

    password = "";
    username = "";
    setUser!: any;

    async handleSubmit(e: Event): Promise<any> {
        e.preventDefault();
        await store.dispatch(
            storeTypes.auth.actions.login({username: this.username, password: this.password})
        );
    }

}