import { Component } from "vue-property-decorator";
import {VueWizard} from "@/vue-wizard";
// Components
import LoginForm from "@/app/components/login-form/LoginForm.vue";
import {store} from "@/app/store";


@Component({
    name: "app-login",
    components: { LoginForm }
})
export default class AppLogin extends VueWizard {}